var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-starts"
  }, [_c('div', {
    staticClass: "mt-1 mr-2"
  }, [_c('div', {
    staticClass: "font-weight-bold primary--text"
  }, [_vm._t("title")], 2), _c('div', {
    staticClass: "text-caption secondary--text"
  }, [_vm._t("description")], 2)]), _c('v-spacer'), _c('TierSelector', {
    staticClass: "mt-1",
    on: {
      "input": function input(tier) {
        return _vm.$emit('input', tier);
      }
    },
    model: {
      value: _vm.tier,
      callback: function callback($$v) {
        _vm.tier = $$v;
      },
      expression: "tier"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }