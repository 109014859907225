<template>
  <div class='d-flex align-starts'>
    <!--<v-switch inset dense class='mr-2 mt-0'
      :value='toggle' @change='change'
      :true-value='true' :false-value='false'
    />-->

    <div class='mt-1 mr-2'>
      <div class='font-weight-bold primary--text'><slot name='title' /></div>
      <div class='text-caption secondary--text'><slot name='description' /></div>
    </div>
    <v-spacer />
    <TierSelector class='mt-1' v-model='tier' @input="tier=>$emit('input', tier)" />

  </div>
</template>

<style lang="scss">
//@import "@/assets/common";
</style>

<script>
import TierSelector from '@/components/Billing/TierSelector';
export default {
  name: 'Tier',

  components: {
    TierSelector,
  },

  props: {
    value: { type: Object, required: false, default: null },
  },

  watch: {
    value(n, o){ console.log("do refresh?", n, o); this.refreshValue(); },
  },

  data: () => ({
    tier: null,
  }),

  computed: { },

  methods:{
    change(e){ this.$emit('input', e); },
    refreshValue(){ console.log("refresh", this.value); this.tier = this.value; },
  },

  mounted() { this.refreshValue(); },

};
</script>
