var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "xv-model": "on"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('div', _vm._g({}, on), [_c('TierIcon', {
          staticStyle: {
            "cursor": "pointer",
            "position": "relative"
          },
          attrs: {
            "tier": _vm.displayTier,
            "size": "40px",
            "tooltip": ""
          }
        })], 1)];
      }
    }])
  }, [_c('v-card', {
    staticClass: "py-1"
  }, [_c('v-autocomplete', {
    staticStyle: {
      "width": "170px"
    },
    attrs: {
      "solo": "",
      "flat": "",
      "dense": "",
      "hide-details": "",
      "autofocus": "",
      "open-on-focus": "",
      "open-delay": "500",
      "loading": _vm.loading,
      "items": _vm.tiers,
      "item-text": "code",
      "item-value": "xid",
      "clearable": "",
      "placeholder": "No Charge"
    },
    on: {
      "focus": _vm.loadTiers,
      "input": _vm.change
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('TierIcon', {
          staticClass: "my-1 mr-4",
          attrs: {
            "tier": data.item,
            "size": "30px",
            "tooltip": ""
          }
        })];
      }
    }, {
      key: "selection",
      fn: function fn(data) {
        return [_c('TierIcon', {
          staticClass: "mr-4 flex-shrink-0",
          attrs: {
            "tier": data.item,
            "size": "30px",
            "tooltip": ""
          }
        })];
      }
    }]),
    model: {
      value: _vm.selectedXid,
      callback: function callback($$v) {
        _vm.selectedXid = $$v;
      },
      expression: "selectedXid"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }