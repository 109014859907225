<template>

  <div>
    <v-tooltip bottom :disabled="!tooltip">
      <template v-slot:activator="{on}">
        <v-sheet
            :color="tier.colour"
            elevation="6"
            :height="size"
            :width="size"
            class='tierChip rounded-lg'
            style='2'
            @click='$emit("click")'
            v-on="on"
          >
          <span class='font-weight-bold' :style='{ fontSize, color: tier.text_col}'>{{tier.code}}</span>
        </v-sheet>
      </template>
      <span>${{(tier.price/100).toFixed(2)}}</span>
      <span v-if='tier.description'><br>{{tier.description}}</span>
    </v-tooltip>

  </div>

</template>

<style lang="scss">
//@import "@/assets/common";
.tierChip{
  display: grid;
  place-items: center;
  line-height: 1;
}
</style>

<script>

export default {
  name: 'TierIcon',

  components: {
  },

  props: {
    tier: { type: Object, required: true },
    size: { type: String, required: true },
    tooltip: { type: Boolean, required: false, default: false },
  },

  data: () => ({
  }),

  computed: {
    fontSize(){
      let size = {
        1: `calc(${this.size} / 1.5`,
        2: `calc(${this.size} / 1.8`,
        3: `calc(${this.size} / 2.8`,
      };
      return size[this.tier.code.length];
    }
  },

  methods:{
  },

  mounted() {
  },

};
</script>
