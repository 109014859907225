var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('div', {
    staticClass: "overflow-y-auto px-2 pt-0 pb-2 mx-2",
    staticStyle: {
      "flex-grow": "0",
      "width": "300px",
      "border-left": "1px solid #0001"
    }
  }, [_c('v-treeview', {
    staticClass: "prescribeMenu",
    attrs: {
      "activatable": "",
      "dense": "",
      "items": _vm.treeItems,
      "item-key": "key",
      "active": _vm.treeActive
    },
    on: {
      "update:active": function updateActive(keys) {
        _vm.treeActive = keys;
      }
    },
    model: {
      value: _vm.treeModel,
      callback: function callback($$v) {
        _vm.treeModel = $$v;
      },
      expression: "treeModel"
    }
  }), _c('v-btn', {
    staticClass: "primary ma-1 mt-4",
    attrs: {
      "block": "",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.updatePrescribe
    }
  }, [_vm._v("Publish Settings")])], 1), _c('div', {
    staticClass: "pa-1 pb-2 overflow-y-auto",
    staticStyle: {
      "flex": "1 0 300px"
    }
  }, [_c('v-card', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "disabled": _vm.loading,
      "loading": _vm.loading
    }
  }, [_c('v-card-text', {
    staticClass: "py-2"
  }, [!_vm.activeOptions.length ? _c('span', {
    staticClass: "font-italic text-caption"
  }, [_vm._v("No options available.")]) : _vm._e(), _vm._l(_vm.activeOptions, function (option) {
    return _c('div', {
      key: option.id,
      staticClass: "py-1"
    }, [option.type == "switch" ? _c('span', [_c('Toggle', {
      staticClass: "mb-4",
      attrs: {
        "value": _vm.switches[option.key]
      },
      on: {
        "input": function input(t) {
          return _vm.toggle(option.key, t);
        }
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_vm._v(_vm._s(option.title))];
        },
        proxy: true
      }, {
        key: "description",
        fn: function fn() {
          return [_vm._v(_vm._s(option.description))];
        },
        proxy: true
      }], null, true)
    })], 1) : _vm._e(), option.type == "list" ? _c('span', [_c('List', {
      staticClass: "mb-4",
      attrs: {
        "items": _vm.lists[option.key]
      },
      on: {
        "add": function add($event) {
          return _vm.addListItem(option.key);
        },
        "delete": _vm.deleteListItem,
        "duplicate": _vm.duplicateListItem,
        "input": _vm.updateListItem,
        "sort": _vm.sortListItems
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_vm._v(_vm._s(option.title))];
        },
        proxy: true
      }, {
        key: "description",
        fn: function fn() {
          return [_vm._v(_vm._s(option.description))];
        },
        proxy: true
      }], null, true)
    })], 1) : _vm._e(), option.type == "number" ? _c('span', [_c('NumberInput', {
      attrs: {
        "suffix": option.suffix
      },
      on: {
        "input": function input(v) {
          return _vm.updateNumber(option.key, v);
        }
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_vm._v(_vm._s(option.title))];
        },
        proxy: true
      }, {
        key: "description",
        fn: function fn() {
          return [_vm._v(_vm._s(option.description))];
        },
        proxy: true
      }], null, true),
      model: {
        value: _vm.numbers[option.key],
        callback: function callback($$v) {
          _vm.$set(_vm.numbers, option.key, $$v);
        },
        expression: "numbers[option.key]"
      }
    })], 1) : _vm._e(), option.type == "tier" ? _c('span', [_c('Tier', {
      staticClass: "mb-4",
      attrs: {
        "value": _vm.tiers[option.key]
      },
      on: {
        "input": function input(t) {
          return _vm.changeTier(option.key, t);
        }
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_vm._v(_vm._s(option.title))];
        },
        proxy: true
      }, {
        key: "description",
        fn: function fn() {
          return [_vm._v(_vm._s(option.description))];
        },
        proxy: true
      }], null, true)
    })], 1) : _vm._e(), option.type == "image" ? _c('span', [_c('ImageX', {
      staticClass: "mb-4",
      attrs: {
        "value": _vm.images[option.key]
      },
      on: {
        "input": function input(t) {
          return _vm.changeImage(option.key, t);
        }
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_vm._v(_vm._s(option.title))];
        },
        proxy: true
      }, {
        key: "description",
        fn: function fn() {
          return [_vm._v(_vm._s(option.description))];
        },
        proxy: true
      }], null, true)
    })], 1) : _vm._e(), option.type == "text" ? _c('span', [_c('TextInput', {
      on: {
        "input": function input(v) {
          return _vm.changeText(option.key, v);
        }
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_vm._v(_vm._s(option.title))];
        },
        proxy: true
      }, {
        key: "description",
        fn: function fn() {
          return [_vm._v(_vm._s(option.description))];
        },
        proxy: true
      }], null, true),
      model: {
        value: _vm.texts[option.key],
        callback: function callback($$v) {
          _vm.$set(_vm.texts, option.key, $$v);
        },
        expression: "texts[option.key]"
      }
    })], 1) : _vm._e()]);
  })], 2)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }