<template>
  <div>
    <v-menu :close-on-content-click='false' xv-model='on'>
      <template #activator="{on}">
        <div v-on="on">
          <TierIcon :tier='displayTier' size="40px" style='cursor:pointer;position:relative;' tooltip />
        </div>
      </template>
      <v-card class='py-1'>
        <v-autocomplete
          solo flat dense hide-details
          autofocus open-on-focus open-delay='500'
          :loading='loading'
          :items='tiers' item-text='code' item-value='xid' clearable
          v-model='selectedXid'
          @focus='loadTiers'
          style='width: 170px;'
          placeholder='No Charge'
          @input='change'
        >
          <template v-slot:item="data">
            <TierIcon :tier="data.item" size="30px" class='my-1 mr-4' tooltip />
          </template>
          <template v-slot:selection="data">
            <TierIcon :tier="data.item" size="30px" class='mr-4 flex-shrink-0' tooltip />
          </template>
        </v-autocomplete>
      </v-card>
    </v-menu>

  </div>
</template>

<style lang="scss">
//@import "@/assets/common";
</style>

<script>
import { mapActions } from 'vuex';
import TierIcon from './TierIcon';

export default {
  name: '',

  props: {
    value: { type: Object, required: false, },
  },

  components: {
    TierIcon,
  },

  data: () => ({
    loaded: false,
    loading: false,
    tiers: [],
    selectedXid: null,
    xon: false,
  }),

  computed: {
    selected(){ return this.tiers.find(t => t.xid == this.selectedXid) || null; },
    displayTier(){
      return this.selected || { "code": "?", "description": "No Charge Selected", "price": 0, "colour": "#00AD79", "xid": "123", "text_col": "#ffffff" };
    }
  },

  watch: {
    value(){ this.refreshValue(); }
  },

  methods:{
    ...mapActions("API", ["API"]),

    loadTiers(){
      if(this.loaded) return;
      this.loading = true;
      this.API({ method: "GET", endpoint: "tiers" })
      .then(tiers => {
        this.tiers = tiers;
        this.loaded = true;
      })
      .finally(() => { this.loading = false; });
    },

    change(e){
      this.$emit("input", this.tiers.find(t=>t.xid == e));
    },

    refreshValue(){
      if(!this.loaded) this.tiers = this.value ? [this.value] : [];
      this.selectedXid = this.value?.xid ?? null;
    }

  },

  mounted() {
    this.refreshValue();
  },

};
</script>
