<template>
  <div class='' style='display:flex;'>

    <div class='overflow-y-auto px-2 pt-0 pb-2 mx-2' style='flex-grow:0;width:300px;border-left:1px solid #0001;'>
      <v-treeview
        class='prescribeMenu'
        activatable dense
        v-model='treeModel'
        :items='treeItems' item-key='key'
        :active='treeActive' @update:active='keys => { treeActive = keys; }'
      />
      <v-btn block class='primary ma-1 mt-4' @click='updatePrescribe' :disabled='loading'>Publish Settings</v-btn>
    </div>

    <div class='pa-1 pb-2 overflow-y-auto' style='flex:1 0 300px;'>
      <v-card :disabled='loading' :loading='loading' style='width:100%;'>
        <v-card-text class='py-2'>
          <span v-if='!activeOptions.length' class='font-italic text-caption'>No options available.</span>
          <div v-for='(option) in activeOptions' v-bind:key='option.id' class='py-1'>
            <span v-if='option.type=="switch"'>
              <Toggle class='mb-4' :value='switches[option.key]' @input='t=>toggle(option.key, t)'>
                <template #title>{{option.title}}</template>
                <template #description>{{option.description}}</template>
              </Toggle>
            </span>
            <span v-if='option.type=="list"'>
              <List class='mb-4'
                :items="lists[option.key]"
                @add="addListItem(option.key)"
                @delete="deleteListItem"
                @duplicate="duplicateListItem"
                @input='updateListItem'
                @sort='sortListItems'
              >
                <template #title>{{option.title}}</template>
                <template #description>{{option.description}}</template>
              </List>
            </span>
            <span v-if='option.type=="number"'>
              <NumberInput :suffix='option.suffix'
                v-model="numbers[option.key]"
                @input='v=>updateNumber(option.key, v)'
              >
                <template #title>{{option.title}}</template>
                <template #description>{{option.description}}</template>
              </NumberInput>
            </span>
            <span v-if='option.type=="tier"'>
              <Tier class='mb-4' :value='tiers[option.key]' @input='t=>changeTier(option.key, t)'>
                <template #title>{{option.title}}</template>
                <template #description>{{option.description}}</template>
              </Tier>
            </span>
            <span v-if='option.type=="image"'>
              <ImageX class='mb-4' :value='images[option.key]' @input='t=>changeImage(option.key, t)'>
                <template #title>{{option.title}}</template>
                <template #description>{{option.description}}</template>
              </ImageX>
            </span>
            <span v-if='option.type=="text"'>
              <TextInput
                v-model="texts[option.key]"
                @input='v=>changeText(option.key, v)'
              >
                <template #title>{{option.title}}</template>
                <template #description>{{option.description}}</template>
              </TextInput>
            </span>
          </div>

        </v-card-text>
      </v-card>
    </div>

  </div>
</template>

<style lang="scss">
@import "@/assets/common";
*::-webkit-scrollbar
{
  width: 8px;
  height: 8px;
  background-color: transparent;
  &-thumb{
    border-radius: 10px;
    background-color: rgba($secondary, 0.5);
    &:hover{
      background-color: rgba($secondary, 1);
    }
  }
}
.prescribeMenu{
  .v-treeview-node__root{
    font-family: "Rubik", sans-serif;
    border-radius: 8px;
    margin-bottom: 3px;
    padding: 2px 0px;
    min-height: 0px;
    font-size:0.9em;
    cursor: pointer;
    &:hover{
      background-color: rgba($primary, 10%);
    }
    &.v-treeview-node--active{
      background-color: $primary;
      .v-treeview-node__label{ color: white; }
      &:hover{
        background-color: lighten($primary, 2%);
        .v-treeview-node__label{ color: white; }
      }
    }
  }
}
</style>

<script>
import { mapActions } from 'vuex'
import Toggle from '@/components/PrescribeSettings/Toggle'
import List from '@/components/PrescribeSettings/List'
import NumberInput from '@/components/PrescribeSettings/Number'
import Tier from '@/components/PrescribeSettings/Tier'
import ImageX from '@/components/PrescribeSettings/Image'
import TextInput from '@/components/PrescribeSettings/Text'
import settingsMenu from '@/components/PrescribeSettings/settingsMenu'
export default {
  name: 'Prescribe',

  components: {
    Toggle, List, NumberInput, Tier, ImageX, TextInput
  },

  data: () => ({
    loading: false,
    treeModel: [],
    treeActive: [],
    treeItems: settingsMenu,
    switches: {
      ...[
        'eva_enabled', 'eva_new_icon',
        'eva_sizes_mens_wide', 'eva_sizes_womens_athletic', 'eva_sizes_womens_dress',
        'printed_enabled', 'printed_new_icon',
        'vac_enabled', 'vac_new_icon',
        'exol_enabled', 'exol_new_icon',
      ].reduce((keys, k)=>({ ...keys, [k]: null }), {}),
    },
    lists:{
      ...[
        'eva_aperture_materials', 'eva_aperture_covers', 'eva_raise_materials', 'eva_dell_materials',
        'eva_upad_ppt_materials', 'eva_upad_eva_materials',
        'eva_metdomes', 'eva_metdome_styles', 'eva_metpads', 'eva_metpad_styles',
        'eva_orthosis_styles', 'eva_orthosis_size_styles',
        'eva_extra_sole_materials', 'eva_shoe_plates', 'eva_orthosis_materials',
        'eva_top_covers', 'eva_plantar_covers', 'eva_top_cover_attachment',
        'eva_heel_lift_materials', 'eva_full_length_wedge', 'eva_gait_plate_materials',
        'eva_first_ray_ext_eva', 'eva_first_ray_ext_ppt',
        'eva_rev_mortons_eva', 'eva_rev_mortons_ppt', 'eva_mortons_eva', 'eva_mortons_poly',
        'eva_turnaround',
        'printed_aperture_materials', 'printed_aperture_covers', 'printed_raise_materials', 'printed_dell_materials',
        'printed_upad_ppt_materials', 'printed_upad_eva_materials',
        'printed_metdomes', 'printed_metdome_styles', 'printed_metpads', 'printed_metpad_styles',
        'printed_post_materials', 'printed_orthosis_materials',
        'printed_top_covers', 'printed_top_cover_attachment', 'printed_plantar_covers',
        'printed_turnaround', 'printed_script_order_override',
        'vac_aperture_materials', 'vac_raise_materials', 'vac_dell_materials',
        'vac_upad_ppt_materials', 'vac_upad_eva_materials',
        'vac_metdomes', 'vac_metdome_styles', 'vac_metpads', 'vac_metpad_styles',
        'vac_post_materials',
        'vac_plantar_arch_fill_materials',
        'vac_orthosis_materials', 'vac_top_covers', 'vac_top_cover_attachment', 'vac_plantar_covers',
        'vac_turnaround',

        'exol_colour', 'exol_lining',
        'exol_turnaround',

      ].reduce((keys, k)=>({ ...keys, [k]: [] }), {}),
    },
    numbers: {
      ...[
        'eva_aperture_adjacent', 'eva_aperture_forefoot', 'eva_aperture_heel',
        'eva_raise_cuboid', 'eva_raise_styloid', 'eva_raise_navicular',
        'eva_dell_cuboid', 'eva_dell_styloid', 'eva_dell_navicular',
        'eva_extra_sole_thickness',
        'printed_aperture_adjacent', 'printed_aperture_forefoot', 'printed_aperture_heel',
        'printed_raise_cuboid', 'printed_raise_styloid', 'printed_raise_navicular',
        'printed_dell_cuboid', 'printed_dell_styloid', 'printed_dell_navicular',
        'printed_post_heel', 'printed_post_forefoot',
        'vac_aperture_adjacent', 'vac_aperture_forefoot', 'vac_aperture_heel',
        'vac_raise_cuboid', 'vac_raise_styloid', 'vac_raise_navicular',
        'vac_dell_cuboid', 'vac_dell_styloid', 'vac_dell_navicular',
        'vac_post_heel', 'vac_post_forefoot',
        'vac_plantar_arch_fill_contour',

      ].reduce((keys, k)=>({ ...keys, [k]: null }), {}),
    },
    tiers: {
      eva_plantar_grind: null,
      eva_45_coutout: null,
      eva_sneaker_grind: null,
      eva_fit_shoe_provided: null,
      eva_fit_shoe_template: null,
      eva_fit_shoe_code: null,
      eva_revere_velcro: null,
      eva_top_cover_extension: null,
      eva_no_undercover: null,
      eva_round_poron: null,
      eva_laminated_arch: null,
      eva_first_ray_grind: null,
      eva_maintain_plantarflexed_first: null,
      eva_maintain_forefoot: null,
      eva_heel_cup_height: null,
      eva_heel_expansion: null,
      eva_MLA_bulk_reduction: null,
      eva_rev_mortons_intrinsic: null,
      eva_flangeflare: null,
      eva_machining_service: null,
      printed_fit_shoe_provided: null,
      printed_fit_shoe_template: null,
      printed_fit_shoe_code: null,
      printed_top_cover_extension: null,
      vac_post_lateral_border: null,
      vac_fit_shoe_provided: null,
      vac_fit_shoe_template: null,
      vac_fit_shoe_code: null,
      vac_top_cover_extension: null,
      exol_style_exo: null,
      exol_style_exo_up: null,
      exol_engraving: null,
    },
    texts: {
      header_contact: null,
      eva_script_order_override: null,
      eva_help_tooltips_override: null,
      eva_fit_shoe_brands: null,
      printed_fit_shoe_brands: null,
      printed_script_order_override: null,
      printed_help_tooltips_override: null,
      vac_fit_shoe_brands: null,
      vac_script_order_override: null,
      vac_help_tooltips_override: null,
      exol_script_order_override: null,
      exol_help_tooltips_override: null,

    },
    images: { /* base64 PNG's */
      header_image: null,
      eva_header_image: null,
      printed_header_image: null,
      vac_header_image: null,
      exol_header_image: null,
    }

  }),

  computed: {
    activeMenu(){ return this.treeActive[0] ?? null; }, //active menu option key

    activeMenuItem(){ //the currently active menu item object
      let findChild = (tree, key) => { //deep search a list of menu items for the key
        for(let i=0; i < tree.length; i+= 1){
          if(tree[i].key == key) return tree[i]; //match
          if("children" in tree[i] == false) continue; // next loop if no children
          let childSearch = findChild(tree[i].children, key); //nested search for children
          if(childSearch !== false) return childSearch; //nested match
        }
        return false;
      };
      return findChild(this.treeItems, this.activeMenu) ?? null;
    },

    activeOptions(){ return this.activeMenuItem?.settings ?? []; }, //settings to display for selected menu
  },

  methods:{
    ...mapActions("API", ["API"]),
    ...mapActions("UI", ["CONFIRM"]),

    toggle(toggle_key, value){
      let oldValue = this.switches[toggle_key];
      this.loading = true;
      this.switches[toggle_key] = value;
      this.API({ method: "PATCH", endpoint: `labdata/switch/${toggle_key}`, data: { value } })
      .catch(() => { this.switches[toggle_key] = oldValue; })
      .finally(() => { this.loading = false; });
    },

    addListItem(list_key){
      this.loading = true;
      let data = { key: list_key };
      this.API({ method: 'POST', endpoint: 'labdata/listitem/create', data })
      .then(this.refreshData)
      .finally(() => { this.loading = false; });
    },

    deleteListItem(xid){
      this.loading = true;
      this.API({ method: 'DELETE', endpoint: `labdata/listitem/${xid}` })
      .then(this.refreshData)
      .finally(() => { this.loading = false; });
    },

    duplicateListItem(xid){
      this.loading = true;
      this.API({ method: 'POST', endpoint: `labdata/listitem/${xid}/duplicate` })
      .then(this.refreshData)
      .finally(() => { this.loading = false; });
    },

    updateListItem(update){
      this.API({ method: 'PATCH', endpoint: `labdata/listitem/${update.xid}`, data: update, autosave: 750 });
    },

    sortListItems(sort_order){
      this.loading = true;
      let data = { sort_order };
      this.API({ method: 'POST', endpoint: 'labdata/listitem/sort_order', data })
      .then(this.refreshData)
      .finally(() => { this.loading = false; });
    },

    updateNumber(number_key, value){
      let data = { value };
      this.API({ method: 'PATCH', endpoint: `labdata/number/${number_key}`, data, autosave: 750 });
    },

    changeTier(tier_key, tier){
      let oldValue = this.tiers[tier_key];
      this.loading = true;
      this.tiers[tier_key] = tier;
      this.API({ method: "PATCH", endpoint: `labdata/tier/${tier_key}`, data: { tier: tier?.xid || null } })
      .catch(() => { this.tiers[tier_key] = oldValue; })
      .finally(() => { this.loading = false; });
    },

    refreshData(){
      this.loading = true;
      this.API({ method: 'GET', endpoint: 'labdata' })
      .then(data => {
        //clear the data
        Object.keys(this.switches).forEach(k => { this.switches[k] = null; });
        Object.keys(this.lists).forEach(k => { this.lists[k] = []; });
        //import switches
        data.switches.forEach(({key, value}) => {
          if(key in this.switches) this.switches[key] = value;
        });
        //import list items
        data.list_items.forEach(item => {
          if(item.key in this.lists) this.lists[item.key].push(item);
        });
        //import number items
        data.numbers.forEach(item => {
          if(item.key in this.numbers) this.numbers[item.key] = item.value;
        });
        //import text items
        data.texts.forEach(item => {
          if(item.key in this.texts) this.texts[item.key] = item.value;
        });
        //import image items
        data.images.forEach(item => {
          if(item.key in this.images) this.images[item.key] = item.image;
        });
        //import tier items
        data.tiers.forEach(item => {
          if(item.key in this.tiers){
            this.tiers[item.key] = item.tier;
          }
        });
      })
      .finally(() => { this.loading = false; });
    },

    changeText(key, value){
      let oldValue = this.texts[key];
      this.loading = true;
      this.texts[key] = value;
      this.API({ method: "PATCH", endpoint: `labdata/text/${key}`, data: { value } })
      .catch(() => { this.texts[key] = oldValue; })
      .finally(() => { this.loading = false; });
    },

    changeImage(key, image){
      let oldValue = this.images[key];
      this.loading = true;
      this.images[key] = image;
      this.API({ method: "PATCH", endpoint: `labdata/image/${key}`, data: { image } })
      .catch(() => { this.images[key] = oldValue; })
      .finally(() => { this.loading = false; });
    },

    updatePrescribe(){
      this.CONFIRM({ title: 'Publish Settings', message: 'Publish the current settings to Lasercam Prescribe?' })
      .then(() => {
        this.loading = true;
        this.API({ method: "POST", endpoint: `labdata/publish` })
        .then(() => {
          this.ALERT({ title: "Publish Settings", message: "Lasercam Prescribe settings have been updated." });
        })
        .finally(() => { this.loading = false; });
      })
    },



  },

  mounted() {
    this.refreshData();
  },

};
</script>
