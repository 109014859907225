var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-starts"
  }, [_c('div', {
    staticClass: "mt-1 mr-2"
  }, [_c('div', {
    staticClass: "font-weight-bold primary--text"
  }, [_vm._t("title")], 2), _c('div', {
    staticClass: "text-caption secondary--text"
  }, [_vm._t("description")], 2), _c('v-file-input', {
    attrs: {
      "accept": ".png",
      "placeholder": "select image..."
    },
    on: {
      "change": _vm.fileInputChanged
    },
    model: {
      value: _vm.fileInput,
      callback: function callback($$v) {
        _vm.fileInput = $$v;
      },
      expression: "fileInput"
    }
  })], 1), _c('v-spacer'), _c('div', {
    staticClass: "flex-shrink-0 flex-grow-0",
    staticStyle: {
      "width": "300px"
    }
  }, [_vm.showImage ? _c('v-sheet', {
    staticClass: "pa-0",
    attrs: {
      "outlined": "",
      "elevation": "1"
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.image,
      "max-width": "300",
      "max-height": "300",
      "contain": "",
      "outlined": ""
    }
  })], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }