var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-starts"
  }, [_c('div', {
    staticClass: "mt-1 mr-2"
  }, [_c('div', {
    staticClass: "font-weight-bold primary--text"
  }, [_vm._t("title")], 2), _c('div', {
    staticClass: "text-caption secondary--text"
  }, [_vm._t("description")], 2)]), _c('v-spacer'), _c('div', {
    staticClass: "flex-shrink-0 flex-grow-0",
    staticStyle: {
      "width": "150px"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "suffix": _vm.suffix,
      "type": "number",
      "value": _vm.value
    },
    on: {
      "input": _vm.change
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }