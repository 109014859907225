<template>
  <div class='d-flex align-starts'>
    <v-switch inset dense class='mr-2 mt-0'
      :value='toggle' @change='change'
      :true-value='true' :false-value='false'
    />
    <div class='mt-1'>
      <div class='font-weight-bold'><slot name='title' /></div>
      <div class='text-caption secondary--text'><slot name='description' /></div>
    </div>
  </div>
</template>

<style lang="scss">
//@import "@/assets/common";
</style>

<script>

export default {
  name: 'Toggle',

  components: {
  },

  props: {
    value: { type: Boolean, required: false, default: false },
  },

  watch: {
    value(){ this.refreshToggle(); },
  },

  data: () => ({
    toggle: false,
  }),

  computed: { },

  methods:{
    change(e){ this.$emit('input', e); },
    refreshToggle(){ this.toggle = this.value; },
  },

  mounted() { this.refreshToggle(); },

};
</script>
