<template>
  <div>

    <div class='d-flex my-1 mt-2'>
      <div class='mx-1 flex-grow-1'>
        <div class='font-weight-medium primary--text'><slot name='title' /></div>
        <div class='text-caption secondary--text'><slot name='description' /></div>
      </div>
    </div>

    <div ref='sortArea' class='my-1'>
      <v-row v-for='item in items' :key="item.xid" :sortableId="item.xid">
        <v-col class='d-flex pb-0'>
          <v-icon class='dragMe mx-1'>mdi-drag</v-icon>
          <v-text-field dense hide-details outlined class='mx-1' v-model='item.value' @input="text=>$emit('input', { xid: item.xid, text })"></v-text-field>
          <TierSelector class='mx-1' v-model='item.tier' @input="tier=>$emit('input', { xid: item.xid, tier: tier.xid })" />
          <v-menu>
            <template #activator="{on,attrs}">
              <v-btn fab small text v-on="on" v-bind="attrs">
                <v-icon class='mx-1'>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item link @click="$emit('duplicate', item.xid)"><v-icon class="mr-4 primary--text">mdi-content-copy</v-icon>Duplicate</v-list-item>
              <v-list-item link @click="$emit('delete', item.xid)"><v-icon class="mr-4 primary--text">mdi-trash-can</v-icon>Delete</v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </div>

    <v-row class='my-1'>
      <v-col>
        <v-btn small text color='primary' @click='$emit("add")'><v-icon class='mr-2'>mdi-plus-circle</v-icon>Add Row</v-btn>
      </v-col>
    </v-row>

  </div>
</template>

<style lang="scss" scoped>
//@import "@/assets/common";
.dragMe{
  cursor: move;
}
</style>

<script>
import { mapActions } from 'vuex';
import TierSelector from '@/components/Billing/TierSelector';
import Sortable from 'sortablejs';
export default {
  name: 'List',

  components: {
    TierSelector
  },

  props: {
    items: { type: Array, required: false, default: new Array },
  },

  watch: {
    value(){ this.refreshToggle(); },
  },

  data: () => ({
    toggle: false,
  }),

  computed: { },

  methods:{
    ...mapActions("UI", ["PROMPT"]),

    refreshToggle(){ this.toggle = this.value; },

    makeSortable(){
      this.sortable = new Sortable(this.$refs.sortArea, {
        animation: 250,
        handle: '.dragMe',
        swapThreshold: 1,
        dataIdAttr: 'sortableId',
        onEnd: this.updateOrder,
      });
    },

    updateOrder(e){
      if(e.newIndex == e.oldIndex) return;
      this.$emit('sort', this.sortable.toArray());
    }

  },

  mounted() {
    this.refreshToggle();
    this.makeSortable();
  },

};
</script>
