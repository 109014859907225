var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-1 mr-2"
  }, [_c('div', {
    staticClass: "font-weight-bold primary--text"
  }, [_vm._t("title")], 2), _c('div', {
    staticClass: "text-caption secondary--text",
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_vm._t("description")], 2), _c('v-textarea', {
    ref: "textarea",
    staticClass: "mt-1",
    staticStyle: {
      "max-width": "500px"
    },
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "auto-grow": "",
      "rows": "3",
      "value": _vm.value
    },
    on: {
      "change": _vm.change
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }