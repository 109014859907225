<template>
  <div class='mt-1 mr-2'>
    <div class='font-weight-bold primary--text'><slot name='title' /></div>
    <div class='text-caption secondary--text' style='white-space:pre-wrap;'><slot name='description' /></div>
    <v-textarea
      ref='textarea'
      class='mt-1'
      style='max-width:500px;'
      outlined dense hide-details auto-grow
      rows=3
      :value="value"
      @change='change'
    />
  </div>
</template>

<style lang="scss">
//@import "@/assets/common";
</style>

<script>

export default {
  name: 'Text',

  components: {
  },

  props: {
    value: { type: String, required: false, default: null },
  },

  watch: {
    //value(){ this.updateValue(); },
  },

  data: () => ({
  }),

  computed: {
  },

  methods:{
    change(v){ this.$emit("input", v); },
    updateValue(){
      this.$refs.textarea.value = this.value;
    }
  },

  mounted() {
    //this.updateValue();
  },

};
</script>
