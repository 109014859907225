<template>
  <div class='d-flex align-starts'>
    <div class='mt-1 mr-2'>
      <div class='font-weight-bold primary--text'><slot name='title' /></div>
      <div class='text-caption secondary--text'><slot name='description' /></div>
    </div>
    <v-spacer />
    <div class='flex-shrink-0 flex-grow-0' style='width:150px;'>
      <v-text-field
        outlined dense hide-details
        :suffix="suffix"
        type="number"
        :value="value"
        @input='change'
      />
    </div>
  </div>
</template>

<style lang="scss">
//@import "@/assets/common";
</style>

<script>

export default {
  name: 'Number',

  components: {
  },

  props: {
    value: { type: Number, required: false, default: null },
    suffix: { type: String, required: false, default: null },
  },

  watch: {
  },

  data: () => ({
  }),

  computed: {
  },

  methods:{
    change(v){ this.$emit("input", parseFloat(v) ?? null); }
  },

  mounted() { },

};
</script>
