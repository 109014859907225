<template>
  <div class='d-flex align-starts'>
    <div class='mt-1 mr-2'>
      <div class='font-weight-bold primary--text'><slot name='title' /></div>
      <div class='text-caption secondary--text'><slot name='description' /></div>
      <v-file-input v-model='fileInput' @change='fileInputChanged' accept='.png' placeholder='select image...' />
    </div>
    <v-spacer />
    <div class='flex-shrink-0 flex-grow-0' style='width:300px;'>
      <v-sheet class='pa-0' v-if='showImage' outlined elevation=1>
        <v-img :src="image" max-width="300" max-height="300" contain outlined />
      </v-sheet>
    </div>
  </div>
</template>

<style lang="scss">
//@import "@/assets/common";
</style>

<script>

export default {
  name: 'ImageX',

  components: {
  },

  props: {
    value: { type: String, required: false, default: null },
    suffix: { type: String, required: false, default: null },
  },

  watch: {
    value(){ this.valueChanged(); },
  },

  data: () => ({
    image: null,
    showImage: true,
    fileInput: null,
  }),

  computed: {
  },

  methods:{
    fileInputChanged(){
      let f = this.fileInput ?? null;
      if(f === null) return;
      let reader = new FileReader();
      reader.addEventListener("loadend", ()=> {
        let b64 = reader.result;
        this.$emit('input', b64);
      });
      reader.readAsDataURL(f);
    },
    resetImage(){
      this.fileInput = null;
      this.image = null;
      this.showImage = false;
      this.$nextTick(() => { this.showImage = true; });
    },

    valueChanged(){
      this.resetImage();
      this.image = this.value;
    },

  },

  mounted() {
    this.valueChanged();
  },

};
</script>
