var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-tooltip', {
    attrs: {
      "bottom": "",
      "disabled": !_vm.tooltip
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-sheet', _vm._g({
          staticClass: "tierChip rounded-lg",
          staticStyle: {},
          attrs: {
            "color": _vm.tier.colour,
            "elevation": "6",
            "height": _vm.size,
            "width": _vm.size
          },
          on: {
            "click": function click($event) {
              return _vm.$emit("click");
            }
          }
        }, on), [_c('span', {
          staticClass: "font-weight-bold",
          style: {
            fontSize: _vm.fontSize,
            color: _vm.tier.text_col
          }
        }, [_vm._v(_vm._s(_vm.tier.code))])])];
      }
    }])
  }, [_c('span', [_vm._v("$" + _vm._s((_vm.tier.price / 100).toFixed(2)))]), _vm.tier.description ? _c('span', [_c('br'), _vm._v(_vm._s(_vm.tier.description))]) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }