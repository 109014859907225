var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex my-1 mt-2"
  }, [_c('div', {
    staticClass: "mx-1 flex-grow-1"
  }, [_c('div', {
    staticClass: "font-weight-medium primary--text"
  }, [_vm._t("title")], 2), _c('div', {
    staticClass: "text-caption secondary--text"
  }, [_vm._t("description")], 2)])]), _c('div', {
    ref: "sortArea",
    staticClass: "my-1"
  }, _vm._l(_vm.items, function (item) {
    return _c('v-row', {
      key: item.xid,
      attrs: {
        "sortableId": item.xid
      }
    }, [_c('v-col', {
      staticClass: "d-flex pb-0"
    }, [_c('v-icon', {
      staticClass: "dragMe mx-1"
    }, [_vm._v("mdi-drag")]), _c('v-text-field', {
      staticClass: "mx-1",
      attrs: {
        "dense": "",
        "hide-details": "",
        "outlined": ""
      },
      on: {
        "input": function input(text) {
          return _vm.$emit('input', {
            xid: item.xid,
            text: text
          });
        }
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    }), _c('TierSelector', {
      staticClass: "mx-1",
      on: {
        "input": function input(tier) {
          return _vm.$emit('input', {
            xid: item.xid,
            tier: tier.xid
          });
        }
      },
      model: {
        value: item.tier,
        callback: function callback($$v) {
          _vm.$set(item, "tier", $$v);
        },
        expression: "item.tier"
      }
    }), _c('v-menu', {
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [_c('v-btn', _vm._g(_vm._b({
            attrs: {
              "fab": "",
              "small": "",
              "text": ""
            }
          }, 'v-btn', attrs, false), on), [_c('v-icon', {
            staticClass: "mx-1"
          }, [_vm._v("mdi-dots-horizontal")])], 1)];
        }
      }], null, true)
    }, [_c('v-list', [_c('v-list-item', {
      attrs: {
        "link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.$emit('duplicate', item.xid);
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-4 primary--text"
    }, [_vm._v("mdi-content-copy")]), _vm._v("Duplicate")], 1), _c('v-list-item', {
      attrs: {
        "link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.$emit('delete', item.xid);
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-4 primary--text"
    }, [_vm._v("mdi-trash-can")]), _vm._v("Delete")], 1)], 1)], 1)], 1)], 1);
  }), 1), _c('v-row', {
    staticClass: "my-1"
  }, [_c('v-col', [_c('v-btn', {
    attrs: {
      "small": "",
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit("add");
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("mdi-plus-circle")]), _vm._v("Add Row")], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }