//settings menu options are loaded into the settings page sub navigation.

export default [
  {
    name: 'General', key: 'g',
    settings: [
      { id: 'g1', type: 'image', key: 'header_image', title: 'Header Image', description: 'An image with your business logo, displayed on prescription forms. (PNG 1000x220)' },
      { id: 'g2', type: 'text', key: 'header_contact', title: 'Contact Information', description: 'Contact details shown in the header of prescriptions forms.' },
    ],
    children: []
  },
  {
    name: 'EVA', key: 'eva',
    settings: [
      { id: 'e1', type: 'switch', key: 'eva_enabled', title: 'Enable EVA', description: 'Activates "Machined EVA" in the New Prescription menu.' },
      { id: 'e2', type: 'switch', key: 'eva_new_icon', title: '"New" Icon', description: 'Displays a small "New" icon in the New Prescription menu for attention.' },
      { id: 'e3', type: 'image', key: 'eva_header_image', title: 'Secondary Image', description: 'A second image alongside your logo on EVA prescriptions. (PNG 700x220)' },
    ],
    children: [
      //{ name: 'Intrinsic Adjustments', key: 'eva_ia', children: [] },
      { name: 'Off-Loading', key: 'eva_ol', settings: [], children: [
        { name: 'Raise', key: 'eva_ol_raise', settings: [
          { id: 'eva_ol_raise_1', type: 'list', key: 'eva_raise_materials', title: 'Raise Materials', description: 'Raise materials available and the base price for each raise material.' },
          { id: 'eva_ol_raise_2', type: 'number', key: 'eva_raise_cuboid', title: 'Rate adjustment - Cuboid', description: 'The rate to charge relative to the material chosen.', suffix: '%' },
          { id: 'eva_ol_raise_3', type: 'number', key: 'eva_raise_styloid', title: 'Rate adjustment - Styloid', description: 'The rate to charge relative to the material chosen.', suffix: '%' },
          { id: 'eva_ol_raise_4', type: 'number', key: 'eva_raise_navicular', title: 'Rate adjustment - Navicular', description: 'The rate to charge relative to the material chosen.', suffix: '%' }
        ]},
        { name: 'Dell', key: 'eva_ol_dell', settings: [
          { id: 'eva_ol_dell_1', type: 'list', key: 'eva_dell_materials', title: 'Dell Materials', description: 'Dell materials available and the base price for each dell material.' },
          { id: 'eva_ol_dell_2', type: 'number', key: 'eva_dell_cuboid', title: 'Rate adjustment - Cuboid', description: 'The rate to charge relative to the material chosen.', suffix: '%' },
          { id: 'eva_ol_dell_3', type: 'number', key: 'eva_dell_styloid', title: 'Rate adjustment - Styloid', description: 'The rate to charge relative to the material chosen.', suffix: '%' },
          { id: 'eva_ol_dell_4', type: 'number', key: 'eva_dell_navicular', title: 'Rate adjustment - Navicular', description: 'The rate to charge relative to the material chosen.', suffix: '%' }
        ]},
        { name: 'Apertures', key: 'eva_ol_ap', settings: [
          { id: 'eva_ol_ap_1', type: 'list', key: 'eva_aperture_materials', title: 'Aperture Materials', description: 'Aperture materials available and the base price for a single MPJ aperture.' },
          { id: 'eva_ol_ap_2', type: 'number', key: 'eva_aperture_adjacent', title: 'Adjacent Aperture Rate', description: 'The rate at which additional MPJ apertures are charged when placed next to another of the same material.', suffix: '%' },
          { id: 'eva_ol_ap_3', type: 'number', key: 'eva_aperture_forefoot', title: 'Forefoot Aperture Rate', description: 'The rate at which a forefoot aperture is charged, relative to the single MPJ charge.', suffix: '%' },
          { id: 'eva_ol_ap_4', type: 'number', key: 'eva_aperture_heel', title: 'Heel Aperture Rate', description: 'The rate at which a heel aperture is charged, relative to the single MPJ charge.', suffix: '%' },
          { id: 'eva_ol_ap_5', type: 'list', key: 'eva_aperture_covers', title: 'Aperture Plantar Covers', description: 'Plantar Cover options, and charges. (eg. Default / None / Material)' },
        ]},
        { name: 'U-Pads', key: 'eva_ol_upads', settings: [
          { id: 'eva_ol_upads_1', type: 'list', key: 'eva_upad_ppt_materials', title: 'U-Pad Materials (PPT)', description: 'PPT U-Pad materials & charges.' },
          { id: 'eva_ol_upads_2', type: 'list', key: 'eva_upad_eva_materials', title: 'U-Pad Materials (EVA)', description: 'EVA U-Pad materials & charges.' },
        ]},
        { name: 'Metatarsal Domes', key: 'eva_ol_metdomes', settings: [
          { id: 'eva_metdomes_1', type: 'list', key: 'eva_metdomes', title: 'Metatarsal Domes', description: 'List of Metatarsal Domes & charges.'},
          { id: 'eva_metdomes_2', type: 'list', key: 'eva_metdome_styles', title: 'Metatarsal Dome Styles', description: 'Hand finishing styles (eg. standard, reduce half, bevel) & charges.'}
        ]},
        { name: 'Metatarsal Pads', key: 'eva_ol_metpads', settings: [
          { id: 'eva_metpads_1', type: 'list', key: 'eva_metpads', title: 'Metatarsal Pads', description: 'List of Metatarsal Pads & charges.'},
          { id: 'eva_metpads_2', type: 'list', key: 'eva_metpad_styles', title: 'Metatarsal Pad Styles', description: 'Hand finishing styles (eg. standard, reduce half, bevel) & charges.'}
        ]},
      ] },
      { name: 'Shoe Fitting', key: 'eva_sf', settings: [
          { id: 'eva_sf_1', type: 'tier', key: 'eva_plantar_grind', title: 'Plantar Grind', description: 'Charge for plantar grind.' },
          { id: 'eva_sf_2', type: 'tier', key: 'eva_sneaker_grind', title: 'Sneaker Grind', description: 'Charge for sneaker grind.' },
          { id: 'eva_sf_3', type: 'tier', key: 'eva_45_coutout', title: '45 Degree Cutout', description: 'Charge per medial or lateral cutout.' },
          { id: 'eva_sf_4', type: 'tier', key: 'eva_revere_velcro', title: 'Revere Velcro Fit', description: 'Charge to fit velcro strips.'},
          { id: 'eva_sf_6', type: 'list', key: 'eva_shoe_plates', title: 'Shoe Plates', description: 'Charge for shoe plates. (eg. Polypropylene 2mm)' },
        ], children: [
        { name: 'Fit to Shoe', key: 'eva_sf_fit_shoe', settings: [
          { id: 'eva_sf_fit_shoe_1', type: 'tier', key: 'eva_fit_shoe_provided', title: 'Fit to Shoes Provided', description: 'Charge for fitting orthosis to provided shoes.' },
          { id: 'eva_sf_fit_shoe_2', type: 'tier', key: 'eva_fit_shoe_template', title: 'Fit to Template', description: 'Charge for fitting orthosis to an insole template.' },
          { id: 'eva_sf_fit_shoe_3', type: 'tier', key: 'eva_fit_shoe_code', title: 'Fit to Shoe Style Code', description: 'Charge for fitting orthosis to a known shoe using the shoe manufacturers style code.' },
          { id: 'eva_sf_fit_shoe_4', type: 'text', key: 'eva_fit_shoe_brands', title: 'Shoe Brands', description: 'List of shoe brands (one per line) which the lab can fit using style codes. eg Revere.' },
        ]},
        { name: 'Orthosis Styles', key: 'eva_sf_styles', settings: [
          { id: 'eva_sf_styles_1', type: 'list', key: 'eva_orthosis_styles', title: 'Orthosis Styles', description: 'Styles and charges. eg. Full Length, 3/4 Length' },
        ]},
        { name: 'Orthosis Size Styles', key: 'eva_sf_size_styles', settings: [
          { id: 'eva_sf_size_styles_1', type: 'switch', key: 'eva_sizes_mens_wide', title: "Enable Men's", description: "Enable the Men's (Wide) option." },
          { id: 'eva_sf_size_styles_2', type: 'switch', key: 'eva_sizes_womens_athletic', title: "Enable Women's Athletic", description: "Enable the Women's Athletic (Medium Width) option." },
          { id: 'eva_sf_size_styles_3', type: 'switch', key: 'eva_sizes_womens_dress', title: "Enable Women's Dress", description: "Enable the Women's Dress (Narrow Width) option." },
        ]},
        { name: 'Extra Soles', key: 'eva_sf_soles', settings: [
          { id: 'eva_sf_soles_1', type: 'list', key: 'eva_extra_sole_materials', title: 'Extra Sole Materials', description: 'Extra sole materials, and their base charge for up to 5mm' },
          { id: 'eva_sf_soles_2', type: 'number', key: 'eva_extra_sole_thickness', title: 'Extra Sole Thickness', description: 'Charge rate per 5mm over the initial 5mm base charge for the chosen material.', suffix: '%' },
        ]}
      ] },
      { name: 'Materials', key: 'eva_m', settings: [
        { id: 'eva_m_1', type: 'list', key: 'eva_orthosis_materials', title: 'Orthosis Materials', description: 'List of orthosis colours and densities available, and their charges.' },
      ], children: [
        { name: 'Top Covers', key: 'eva_m_covers', settings: [
          { id: 'eva_m_covers_1', type: 'list', key: 'eva_top_covers', title: 'Top Covers', description: 'Top cover materials, and their charges.' },
          { id: 'eva_m_covers_2', type: 'list', key: 'eva_top_cover_attachment', title: 'Cover Attachment', description: 'Cover attachment (eg. Standard, Unattached etc), and their charges.' },
          { id: 'eva_m_covers_3', type: 'tier', key: 'eva_top_cover_extension', title: 'Cover Extensions', description: 'The charge for the top covers to be extended.' },
          { id: 'eva_m_covers_4', type: 'tier', key: 'eva_no_undercover', title: 'No PU Undercover', description: 'Charge to not have a PU Undercover under the top covers.' },
          { id: 'eva_m_covers_5', type: 'tier', key: 'eva_round_poron', title: 'Round Poron Finish', description: 'Charge to round any PPT covers before applying the final top cover. ' },
        ]},
        { name: 'Plantar Covers', key: 'eva_m_plantar', settings: [
          { id: 'eva_m_plantar_1', type: 'list', key: 'eva_plantar_covers', title: 'Full Length Plantar Covers', description: 'Full length plantar cover materials, and their charges.' },
        ]},
      ]},
      { name: 'Device Options', key: 'eva_do', settings: [], children: [
        { name: 'Intrinsic Options', key: 'eva_do_io', settings: [
          { id: 'eva_do_io_1', type: 'tier', key: 'eva_laminated_arch', title: 'Laminated Arch', description: 'Arch lamination charge.' },
          { id: 'eva_do_io_2', type: 'tier', key: 'eva_first_ray_grind', title: 'First Ray Grind', description: 'First Ray Grind charge.' },
          { id: 'eva_do_io_3', type: 'tier', key: 'eva_maintain_plantarflexed_first', title: 'Maintain Plantarflexed First Ray', description: 'Select a charge.' },
          { id: 'eva_do_io_4', type: 'tier', key: 'eva_maintain_forefoot', title: 'Maintain Forefoot Varus/Valgus', description: 'Select a charge.' },
          { id: 'eva_do_io_5', type: 'tier', key: 'eva_heel_cup_height', title: 'Heel Cup Height', description: 'Select a charge.' },
          { id: 'eva_do_io_6', type: 'tier', key: 'eva_heel_expansion', title: 'Extra Heel Expansion', description: 'Select a charge.' },
          { id: 'eva_do_io_7', type: 'tier', key: 'eva_MLA_bulk_reduction', title: 'MLA Bulk Reduction', description: 'Select a charge.' },
        ]},
        { name: 'Extrinsic Heel Lift', key: 'eva_do_heel_lift', settings: [
          { id: 'eva_do_heel_lift_1', type: 'list', key: 'eva_heel_lift_materials', title: 'Extrinsic Heel Lift Materials', description: 'Charge for extrinsic heel lifts per material.' },
        ]},
        { name: 'Full Length Wedge', key: 'eva_do_wedge', settings: [
          { id: 'eva_do_wedge_1', type: 'list', key: 'eva_full_length_wedge', title: 'Full Length Wedge Materials', description: 'Charge for Full Length Wedge per material.' },
        ]},
        { name: 'Gait Plate', key: 'eva_do_gait_plate', settings: [
          { id: 'eva_do_gait_plate_1', type: 'list', key: 'eva_gait_plate_materials', title: 'Gait Plate Materials', description: 'Gait Plate materials, and their charges.' },
        ]},
        { name: 'First Ray Extension', key: 'eva_do_ext_ray', settings: [
          { id: 'eva_do_ext_ray_1', type: 'list', key: 'eva_first_ray_ext_eva', title: 'EVA Materials', description: 'EVA Materials for First Ray Extensions' },
          { id: 'eva_do_ext_ray_2', type: 'list', key: 'eva_first_ray_ext_ppt', title: 'PPT Materials', description: 'PPT Materials for First Ray Extensions' },
        ]},
        { name: 'Reverse Mortons Extension', key: 'eva_do_rev_mortons', settings: [
          { id: 'eva_do_rev_mortons_1', type: 'tier', key: 'eva_rev_mortons_intrinsic', title: 'Intrinsic Extension', description: 'Charge for an Intrinsic Reverse Mortons Extension' },
          { id: 'eva_do_rev_mortons_2', type: 'list', key: 'eva_rev_mortons_eva', title: 'EVA Materials', description: 'EVA materials. eg. EVA 190 3mm.' },
          { id: 'eva_do_rev_mortons_3', type: 'list', key: 'eva_rev_mortons_ppt', title: 'PPT Materials', description: 'PPT materials. eg. Slow Relase 3.2mm' },
        ]},
        { name: 'Mortons Extension', key: 'eva_do_mortons', settings: [
          { id: 'eva_do_mortons_2', type: 'list', key: 'eva_mortons_eva', title: 'EVA Materials', description: 'EVA Materials. Do not include thickness. eg EVA 220' },
          { id: 'eva_do_mortons_3', type: 'list', key: 'eva_mortons_poly', title: 'Polypropylene Materials', description: 'Polypropylene thicknesses available. eg. 3mm' },
        ]},
        { name: 'Flanges & Flares', key: 'eva_do_flangeflare', settings: [
          { id: 'eva_do_flangeflare_1', type: 'tier', key: 'eva_flangeflare', title: 'Flange / Flare', description: 'Charge per Flange or Flare.' },
        ]},
      ]},
      { name: 'Administration', key: 'eva_na', settings: [
        { id: 'eva_na_1', type: 'tier', key: 'eva_machining_service', title: 'Machining Service', description: 'Charge for only machining the orthotics. Typically a negative value tier.' },
        { id: 'eva_na_2', type: 'list', key: 'eva_turnaround', title: 'Turnaround Time', description: 'Turnaround time and charge. eg. "Express: 3 Days"' },
      ], children: [] },
      { name: 'Advanced', key: 'eva_ad', settings: [], children: [
        { name: 'Script Order', key: 'eva_ad_so', settings: [
          { id: 'eva_ad_so_1', type: 'text', key: 'eva_script_order_override', title: 'Script Order Override (JSON Array)', description: "Changes the order of items on the Prescription PDF.\nSee LINK for more information." },
        ], children: [] },
        { name: 'Help Tooltips', key: 'eva_ad_ht', settings: [
          { id: 'eva_ad_ht_1', type: 'text', key: 'eva_help_tooltips_override', title: 'Help Tooltip Override (JSON Array)', description: "Changes the popup help information for any prescription option.\nSee LINK for more information." },
        ], children: [] },
      ]},
    ]
  },
  {
    name: '3D Printed', key: '3dp',
    settings: [
      { id: 'p1', type: 'switch', key: 'printed_enabled', title: 'Enable 3D Printed', description: 'Activates "3D Printed" in the New Prescription menu.' },
      { id: 'p2', type: 'switch', key: 'printed_new_icon', title: '"New" Icon', description: 'Displays a small "New" icon in the New Prescription menu for attention.' },
      { id: 'p3', type: 'image', key: 'printed_header_image', title: 'Secondary Image', description: 'A second image alongside your logo on 3D Printed prescriptions. (PNG 700x220)' },
  ],
  children: [
    { name: 'Off-Loading', key: 'printed_ol', settings: [], children: [
      { name: 'Raise', key: 'printed_ol_raise', settings: [
        { id: 'printed_ol_raise_1', type: 'list', key: 'printed_raise_materials', title: 'Raise Materials', description: 'Raise materials available and the base price for each raise material.' },
        { id: 'printed_ol_raise_2', type: 'number', key: 'printed_raise_cuboid', title: 'Rate adjustment - Cuboid', description: 'The rate to charge relative to the material chosen.', suffix: '%' },
        { id: 'printed_ol_raise_3', type: 'number', key: 'printed_raise_styloid', title: 'Rate adjustment - Styloid', description: 'The rate to charge relative to the material chosen.', suffix: '%' },
        { id: 'printed_ol_raise_4', type: 'number', key: 'printed_raise_navicular', title: 'Rate adjustment - Navicular', description: 'The rate to charge relative to the material chosen.', suffix: '%' }
      ]},
      { name: 'Dell', key: 'printed_ol_dell', settings: [
        { id: 'printed_ol_dell_1', type: 'list', key: 'printed_dell_materials', title: 'Dell Materials', description: 'Dell materials available and the base price for each dell material.' },
        { id: 'printed_ol_dell_2', type: 'number', key: 'printed_dell_cuboid', title: 'Rate adjustment - Cuboid', description: 'The rate to charge relative to the material chosen.', suffix: '%' },
        { id: 'printed_ol_dell_3', type: 'number', key: 'printed_dell_styloid', title: 'Rate adjustment - Styloid', description: 'The rate to charge relative to the material chosen.', suffix: '%' },
        { id: 'printed_ol_dell_4', type: 'number', key: 'printed_dell_navicular', title: 'Rate adjustment - Navicular', description: 'The rate to charge relative to the material chosen.', suffix: '%' }
      ]},
      { name: 'Apertures', key: 'printed_ol_ap', settings: [
        { id: 'printed_ol_ap_1', type: 'list', key: 'printed_aperture_materials', title: 'Aperture Materials', description: 'Aperture materials available and the base price for a single MPJ aperture.' },
        { id: 'printed_ol_ap_2', type: 'number', key: 'printed_aperture_adjacent', title: 'Adjacent Aperture Rate', description: 'The rate at which additional MPJ apertures are charged when placed next to another of the same material.', suffix: '%' },
        { id: 'printed_ol_ap_3', type: 'number', key: 'printed_aperture_forefoot', title: 'Forefoot Aperture Rate', description: 'The rate at which a forefoot aperture is charged, relative to the single MPJ charge.', suffix: '%' },
        { id: 'printed_ol_ap_4', type: 'number', key: 'printed_aperture_heel', title: 'Heel Aperture Rate', description: 'The rate at which a heel aperture is charged, relative to the single MPJ charge.', suffix: '%' },
        { id: 'printed_ol_ap_5', type: 'list', key: 'printed_aperture_covers', title: 'Aperture Plantar Covers', description: 'Plantar Cover options, and charges. (eg. Default / None / Material)' },
      ]},
      { name: 'U-Pads', key: 'printed_ol_upads', settings: [
        { id: 'printed_ol_upads_1', type: 'list', key: 'printed_upad_ppt_materials', title: 'U-Pad Materials (PPT)', description: 'PPT U-Pad materials & charges.' },
        { id: 'printed_ol_upads_2', type: 'list', key: 'printed_upad_eva_materials', title: 'U-Pad Materials (EVA)', description: 'EVA U-Pad materials & charges.' },
      ]},
      { name: 'Metatarsal Domes', key: 'printed_ol_metdomes', settings: [
        { id: 'printed_metdomes_1', type: 'list', key: 'printed_metdomes', title: 'Metatarsal Domes', description: 'List of Metatarsal Domes & charges.'},
        { id: 'printed_metdomes_2', type: 'list', key: 'printed_metdome_styles', title: 'Metatarsal Dome Styles', description: 'Hand finishing styles (eg. standard, reduce half, bevel) & charges.'}
      ]},
      { name: 'Metatarsal Pads', key: 'printed_ol_metpads', settings: [
        { id: 'printed_metpads_1', type: 'list', key: 'printed_metpads', title: 'Metatarsal Pads', description: 'List of Metatarsal Pads & charges.'},
        { id: 'printed_metpads_2', type: 'list', key: 'printed_metpad_styles', title: 'Metatarsal Pad Styles', description: 'Hand finishing styles (eg. standard, reduce half, bevel) & charges.'}
      ]},
    ]},
    { name: 'Posting', key: 'printed_post', settings: [
      { id: 'printed_post_1', type: 'list', key: 'printed_post_materials', title: 'Post Materials', description: 'List of materials for forefoot and heel posts. You may wish to include "Intrinsic" in this list.'},
      { id: 'printed_post_2', type: 'number', key: 'printed_post_heel', title: 'Heel Post Rate', description: 'The rate to charge relative to the selected material.', suffix: '%' },
      { id: 'printed_post_3', type: 'number', key: 'printed_post_forefoot', title: 'Forefoot Post Rate', description: 'The rate to charge relative to the selected material.', suffix: '%' },
    ], children: []},
    { name: 'Shoe Fitting', key: 'printed_sf', settings: [
      { id: 'printed_sf_1', type: 'tier', key: 'printed_fit_shoe_provided', title: 'Fit to Shoes Provided', description: 'Charge for fitting orthosis to provided shoes.' },
      { id: 'printed_sf_2', type: 'tier', key: 'printed_fit_shoe_template', title: 'Fit to Template', description: 'Charge for fitting orthosis to an insole template.' },
      { id: 'printed_sf_3', type: 'tier', key: 'printed_fit_shoe_code', title: 'Fit to Shoe Style Code', description: 'Charge for fitting orthosis to a known shoe using the shoe manufacturers style code.' },
      { id: 'printed_sf_4', type: 'text', key: 'printed_fit_shoe_brands', title: 'Shoe Brands', description: 'List of shoe brands (one per line) which the lab can fit using style codes. eg Revere.' },
    ], children: []},
    { name: 'Materials', key: 'printed_m', settings: [
      { id: 'printed_m_1', type: 'list', key: 'printed_orthosis_materials', title: 'Orthosis Materials', description: 'List of materials available (eg. PA-11 Nylon), and their charges.' },
    ], children: [
      { name: 'Top Covers', key: 'printed_m_covers', settings: [
        { id: 'printed_m_covers_1', type: 'list', key: 'printed_top_covers', title: 'Top Covers', description: 'Top cover materials, and their charges.' },
        { id: 'printed_m_covers_2', type: 'list', key: 'printed_top_cover_attachment', title: 'Cover Attachment', description: 'Cover attachment (eg. Standard, Unattached etc), and their charges.' },
        { id: 'printed_m_covers_3', type: 'tier', key: 'printed_top_cover_extension', title: 'Cover Extensions', description: 'The charge for the top covers to be extended.' },
      ]},
      { name: 'Plantar Covers', key: 'printed_m_plantar', settings: [
        { id: 'printed_m_plantar_1', type: 'list', key: 'printed_plantar_covers', title: 'Full Length Plantar Covers', description: 'Full length plantar cover materials, and their charges.' },
      ]},
    ]},
    { name: 'Administration', key: 'printed_na', settings: [
      { id: 'printed_na_1', type: 'list', key: 'printed_turnaround', title: 'Turnaround Time', description: 'Turnaround time and charge. eg. "Express: 5 Days"' },
    ], children: [] },
    { name: 'Advanced', key: 'printed_ad', settings: [], children: [
      { name: 'Script Order', key: 'printed_ad_so', settings: [
        { id: 'printed_ad_so_1', type: 'text', key: 'printed_script_order_override', title: 'Script Order Override (JSON Array)', description: "Changes the order of items on the Prescription PDF.\nSee LINK for more information." },
      ], children: [] },
      { name: 'Help Tooltips', key: 'printed_ad_ht', settings: [
        { id: 'printed_ad_ht_1', type: 'text', key: 'printed_help_tooltips_override', title: 'Help Tooltip Override (JSON Array)', description: "Changes the popup help information for any prescription option.\nSee LINK for more information." },
      ], children: [] },
    ]},
  ]},

  {
    name: 'Vacuum Formed', key: 'vac',
    settings: [
      { id: 'v1', type: 'switch', key: 'vac_enabled', title: 'Enable Vacuum Formed', description: 'Activates "Vacuum Formed" in the New Prescription menu.' },
      { id: 'v2', type: 'switch', key: 'vac_new_icon', title: '"New" Icon', description: 'Displays a small "New" icon in the New Prescription menu for attention.' },
      { id: 'v3', type: 'image', key: 'vac_header_image', title: 'Secondary Image', description: 'A second image alongside your logo on Vacuum prescriptions. (PNG 700x220)' },
    ],
    children: [

      { name: 'Off-Loading', key: 'vac_ol', settings: [], children: [
        { name: 'Raise', key: 'vac_ol_raise', settings: [
          { id: 'vac_ol_raise_1', type: 'list', key: 'vac_raise_materials', title: 'Raise Materials', description: 'Raise materials available and the base price for each raise material.' },
          { id: 'vac_ol_raise_2', type: 'number', key: 'vac_raise_cuboid', title: 'Rate adjustment - Cuboid', description: 'The rate to charge relative to the material chosen.', suffix: '%' },
          { id: 'vac_ol_raise_3', type: 'number', key: 'vac_raise_styloid', title: 'Rate adjustment - Styloid', description: 'The rate to charge relative to the material chosen.', suffix: '%' },
          { id: 'vac_ol_raise_4', type: 'number', key: 'vac_raise_navicular', title: 'Rate adjustment - Navicular', description: 'The rate to charge relative to the material chosen.', suffix: '%' }
        ]},
        { name: 'Dell', key: 'vac_ol_dell', settings: [
          { id: 'vac_ol_dell_1', type: 'list', key: 'vac_dell_materials', title: 'Dell Materials', description: 'Dell materials available and the base price for each dell material.' },
          { id: 'vac_ol_dell_2', type: 'number', key: 'vac_dell_cuboid', title: 'Rate adjustment - Cuboid', description: 'The rate to charge relative to the material chosen.', suffix: '%' },
          { id: 'vac_ol_dell_3', type: 'number', key: 'vac_dell_styloid', title: 'Rate adjustment - Styloid', description: 'The rate to charge relative to the material chosen.', suffix: '%' },
          { id: 'vac_ol_dell_4', type: 'number', key: 'vac_dell_navicular', title: 'Rate adjustment - Navicular', description: 'The rate to charge relative to the material chosen.', suffix: '%' }
        ]},
        { name: 'Apertures', key: 'vac_ol_ap', settings: [
          { id: 'vac_ol_ap_1', type: 'list', key: 'vac_aperture_materials', title: 'Aperture Materials', description: 'Aperture materials available and the base price for a single MPJ aperture.' },
          { id: 'vac_ol_ap_2', type: 'number', key: 'vac_aperture_adjacent', title: 'Adjacent Aperture Rate', description: 'The rate at which additional MPJ apertures are charged when placed next to another of the same material.', suffix: '%' },
          { id: 'vac_ol_ap_3', type: 'number', key: 'vac_aperture_forefoot', title: 'Forefoot Aperture Rate', description: 'The rate at which a forefoot aperture is charged, relative to the single MPJ charge.', suffix: '%' },
          { id: 'vac_ol_ap_4', type: 'number', key: 'vac_aperture_heel', title: 'Heel Aperture Rate', description: 'The rate at which a heel aperture is charged, relative to the single MPJ charge.', suffix: '%' }
        ]},
        { name: 'U-Pads', key: 'vac_ol_upads', settings: [
          { id: 'vac_ol_upads_1', type: 'list', key: 'vac_upad_ppt_materials', title: 'U-Pad Materials (PPT)', description: 'PPT U-Pad materials & charges.' },
          { id: 'vac_ol_upads_2', type: 'list', key: 'vac_upad_eva_materials', title: 'U-Pad Materials (EVA)', description: 'EVA U-Pad materials & charges.' },
        ]},
        { name: 'Metatarsal Domes', key: 'vac_ol_metdomes', settings: [
          { id: 'vac_metdomes_1', type: 'list', key: 'vac_metdomes', title: 'Metatarsal Domes', description: 'List of Metatarsal Domes & charges.'},
          { id: 'vac_metdomes_2', type: 'list', key: 'vac_metdome_styles', title: 'Metatarsal Dome Styles', description: 'Hand finishing styles (eg. standard, reduce half, bevel) & charges.'}
        ]},
        { name: 'Metatarsal Pads', key: 'vac_ol_metpads', settings: [
          { id: 'vac_metpads_1', type: 'list', key: 'vac_metpads', title: 'Metatarsal Pads', description: 'List of Metatarsal Pads & charges.'},
          { id: 'vac_metpads_2', type: 'list', key: 'vac_metpad_styles', title: 'Metatarsal Pad Styles', description: 'Hand finishing styles (eg. standard, reduce half, bevel) & charges.'}
        ]},
      ] },

      { name: 'Posting', key: 'vac_post', settings: [
        { id: 'vac_post_1', type: 'list', key: 'vac_post_materials', title: 'Post Materials', description: 'List of materials for forefoot and heel posts. You may wish to include "Intrinsic" in this list.'},
        { id: 'vac_post_2', type: 'number', key: 'vac_post_heel', title: 'Heel Post Rate', description: 'The rate to charge relative to the selected material.', suffix: '%' },
        { id: 'vac_post_3', type: 'number', key: 'vac_post_forefoot', title: 'Forefoot Post Rate', description: 'The rate to charge relative to the selected material.', suffix: '%' },
        { id: 'vac_post_4', type: 'tier', key: 'vac_post_lateral_border', title: 'Lateral Border Post', description: 'Charge for a Lateral Border Post.' },
      ], children: []},

      { name: 'Plantar Modifiers', key: 'vac_pmod', settings: [
        { id: 'vac_pmod_1', type: 'list', key: 'vac_plantar_arch_fill_materials', title: 'Plantar Arch Fill: Materials & Flat Fill Rate', description: 'Materials and Flat Fill Charge for Plantar Arch Fill.'},
        { id: 'vac_pmod_2', type: 'number', key: 'vac_plantar_arch_fill_contour', title: 'Plantar Arch Fill: Contour Rate', description: 'The rate to charge Contoured Plantar Arch Fills, relative to the Flat Fill charge.', suffix: '%' },
      ], children: []},

      { name: 'Shoe Fitting', key: 'vac_sf', settings: [
        { id: 'vac_sf_1', type: 'tier', key: 'vac_fit_shoe_provided', title: 'Fit to Shoes Provided', description: 'Charge for fitting orthosis to provided shoes.' },
        { id: 'vac_sf_2', type: 'tier', key: 'vac_fit_shoe_template', title: 'Fit to Template', description: 'Charge for fitting orthosis to an insole template.' },
        { id: 'vac_sf_3', type: 'tier', key: 'vac_fit_shoe_code', title: 'Fit to Shoe Style Code', description: 'Charge for fitting orthosis to a known shoe using the shoe manufacturers style code.' },
        { id: 'vac_sf_4', type: 'text', key: 'vac_fit_shoe_brands', title: 'Shoe Brands', description: 'List of shoe brands (one per line) which the lab can fit using style codes. eg Revere.' },
      ], children: []},

      { name: 'Materials', key: 'vac_m', settings: [
        { id: 'vac_m_1', type: 'list', key: 'vac_orthosis_materials', title: 'Orthosis Materials', description: 'List of orthosis colours and densities available, and their charges.' },
      ], children: [
        { name: 'Top Covers', key: 'vac_m_covers', settings: [
          { id: 'vac_m_covers_1', type: 'list', key: 'vac_top_covers', title: 'Top Covers', description: 'Top cover materials, and their charges.' },
          { id: 'vac_m_covers_2', type: 'list', key: 'vac_top_cover_attachment', title: 'Cover Attachment', description: 'Cover attachment (eg. Standard, Unattached etc), and their charges.' },
          { id: 'vac_m_covers_3', type: 'tier', key: 'vac_top_cover_extension', title: 'Cover Extensions', description: 'The charge for the top covers to be extended.' },
        ]},
        { name: 'Plantar Covers', key: 'vac_m_plantar', settings: [
          { id: 'vac_m_plantar_1', type: 'list', key: 'vac_plantar_covers', title: 'Full Length Plantar Covers', description: 'Full length plantar cover materials, and their charges.' },
        ]},
      ]},

      { name: 'Administration', key: 'vac_na', settings: [
        { id: 'vac_na_1', type: 'list', key: 'vac_turnaround', title: 'Turnaround Time', description: 'Turnaround time and charge. eg. "Express: 5 Days"' },
      ], children: [] },

      { name: 'Advanced', key: 'vac_ad', settings: [], children: [
        { name: 'Script Order', key: 'vac_ad_so', settings: [
          { id: 'vac_ad_so_1', type: 'text', key: 'vac_script_order_override', title: 'Script Order Override (JSON Array)', description: "Changes the order of items on the Prescription PDF.\nSee LINK for more information." },
        ], children: [] },
        { name: 'Help Tooltips', key: 'vac_ad_ht', settings: [
          { id: 'vac_ad_ht_1', type: 'text', key: 'vac_help_tooltips_override', title: 'Help Tooltip Override (JSON Array)', description: "Changes the popup help information for any prescription option.\nSee LINK for more information." },
        ], children: [] },
      ]},

    ]
  },

  {
    name: 'EXO-L', key: 'exol',
    settings: [
      { id: 'exol_1', type: 'switch', key: 'exol_enabled', title: 'Enable EXO-L', description: 'Activates "EXO-L" in the New Prescription menu.' },
      { id: 'exol_2', type: 'switch', key: 'exol_new_icon', title: '"New" Icon', description: 'Displays a small "New" icon in the New Prescription menu for attention.' },
      { id: 'exol_3', type: 'image', key: 'exol_header_image', title: 'Secondary Image', description: 'A second image alongside your logo on EXO-L prescriptions. (PNG 700x220)' },
    ],
    children: [

      { name: 'Brace', key: 'exol_b', settings: [
        { id: 'exol_b_1', type: 'tier', key: 'exol_style_exo', title: 'EXO-L', description: 'Charge per each EXO-L brace .' },
        { id: 'evol_b_2', type: 'tier', key: 'exol_style_exo_up', title: 'EXO-L UP', description: 'Charge per each EXO-L UP brace.' },
        { id: 'exol_b_3', type: 'list', key: 'exol_colour', title: 'Colour', description: 'List of brace colours available, and their charges.' },
        { id: 'exol_b_4', type: 'list', key: 'exol_lining', title: 'Lining', description: 'List of brace inner lining material/colour options, and their charges.' },
        { id: 'exol_b_5', type: 'tier', key: 'exol_engraving', title: 'Engraving', description: 'Charge for engraving per EXO-L brace.' },
      ]},

      { name: 'Administration', key: 'exol_a', settings: [
        { id: 'exol_a_1', type: 'list', key: 'exol_turnaround', title: 'Turnaround Time', description: 'Turnaround time and charge. eg. "Express: 5 Days"' },
      ]},

      { name: 'Advanced', key: 'exol_ad', settings: [], children: [
        { name: 'Script Order', key: 'exol_ad_so', settings: [
          { id: 'exol_ad_so_1', type: 'text', key: 'exol_script_order_override', title: 'Script Order Override (JSON Array)', description: "Changes the order of items on the Prescription PDF.\nSee LINK for more information." },
        ], children: [] },
        { name: 'Help Tooltips', key: 'exol_ad_ht', settings: [
          { id: 'exol_ad_ht_1', type: 'text', key: 'exol_help_tooltips_override', title: 'Help Tooltip Override (JSON Array)', description: "Changes the popup help information for any prescription option.\nSee LINK for more information." },
        ], children: [] },
      ]},


    ]
  }

];
