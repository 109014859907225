var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-starts"
  }, [_c('v-switch', {
    staticClass: "mr-2 mt-0",
    attrs: {
      "inset": "",
      "dense": "",
      "value": _vm.toggle,
      "true-value": true,
      "false-value": false
    },
    on: {
      "change": _vm.change
    }
  }), _c('div', {
    staticClass: "mt-1"
  }, [_c('div', {
    staticClass: "font-weight-bold"
  }, [_vm._t("title")], 2), _c('div', {
    staticClass: "text-caption secondary--text"
  }, [_vm._t("description")], 2)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }